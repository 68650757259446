"use client";

import { useSearchParams } from "next/navigation";
import { useState, type ReactNode, useEffect } from "react";
import { LinkButton } from "@/components/Button/components/LinkButton/LinkButton.component";
import { useServerConfiguration } from "@/contexts/server-configuration/server-configuration.context";
import { createLoginUrl, createLogoutUrl } from "@/contexts/user/user.context";
type CreateProfileLogoutLoginButtonProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function CreateProfileLogoutLoginButton({
  className,
  children
}: CreateProfileLogoutLoginButtonProps) {
  const {
    authUrl
  } = useServerConfiguration();
  const [host, setHost] = useState<string | null>(null);
  const searchParams = useSearchParams();
  useEffect(() => setHost(window.location.host), []);
  if (host === null) return null;

  // Check if there is an existing redirectUrl in the query params
  // if not we will redirect to the front page of the site
  const redirectUrl = searchParams.get("redirectUrl") ?? "/";
  const loginUrl = `https://${host}${createLoginUrl(redirectUrl)}`;
  const logoutUrl = `https://${host}${createLogoutUrl(loginUrl)}`;
  const logOutToLoginUrl = `${authUrl}/logout?redirect_uri=${encodeURIComponent(logoutUrl)}`;
  return <LinkButton className={className} href={logOutToLoginUrl} openInNewTab={false} data-sentry-element="LinkButton" data-sentry-component="CreateProfileLogoutLoginButton" data-sentry-source-file="CreateProfileLogoutLoginButton.component.tsx">
      {children}
    </LinkButton>;
}