"use client";

import { useEffect, useState, type ChangeEvent } from "react";
import { CheckboxWithLabel } from "@/components/Checkbox/CheckboxWithLabel.component";
import { Link } from "@/components/Link/Link.component";
import { fetchTerms } from "@/components/Terms/_api/fetch-terms";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { captureClientException } from "@/services/logging/capture-client-exception";
type UserTermsCheckboxProps = {
  readonly className?: string;
  readonly id?: string;
  readonly isDisabled?: boolean;
  readonly isRequired?: boolean;
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export function UserTermsCheckbox({
  className,
  isDisabled,
  isRequired = true,
  onChange,
  id
}: UserTermsCheckboxProps) {
  const {
    name
  } = useSite();
  const [url, setUrl] = useState<string>("#");
  useEffect(() => {
    const fetchTermLinks = async () => {
      const result = await fetchTerms();
      setUrl(result?.user ?? "#");
    };
    fetchTermLinks().catch(captureClientException);
  }, []);
  return <CheckboxWithLabel className={className} id={id} isDisabled={isDisabled === true || url === "#"} isRequired={isRequired} onChange={onChange} data-sentry-element="CheckboxWithLabel" data-sentry-component="UserTermsCheckbox" data-sentry-source-file="UserTermsCheckbox.component.tsx">
      <Translation da="Jeg accepterer" de="Ich akzeptiere" en="I accept" no="Jeg godtar" sv="Jag accepterar" data-sentry-element="Translation" data-sentry-source-file="UserTermsCheckbox.component.tsx" />{" "}
      <Link className="text-primary hover:underline" disabled={isDisabled === true || url === "#"} href={url} rel="noreferrer" openInNewTab data-sentry-element="Link" data-sentry-source-file="UserTermsCheckbox.component.tsx">
        <Translation da={`${name}'s brugervilkår`} de={`${name}'s Nutzungsbedingungen`} en={`${name}'s terms of use`} no={`${name}'s brukervilkår`} sv={`${name}'s användarvillkor`} data-sentry-element="Translation" data-sentry-source-file="UserTermsCheckbox.component.tsx" />
      </Link>
    </CheckboxWithLabel>;
}