"use client";

/* eslint-disable react/jsx-no-useless-fragment -- needed to be able to return error messages */
import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/user.context";
import type { ErrorMessageStatus } from "@/services/utilities/fetchable";
type ErrorMessageProps = {
  readonly status: ErrorMessageStatus;
};
export const userAlreadyExistsTranslation = {
  da: "En bruger med denne e-mailadresse findes allerede.",
  de: "Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.",
  en: "A user with this email address already exists.",
  no: "En bruker med denne e-postadressen finnes allerede.",
  sv: "En användare med denna e-postadress finns redan."
};
export function EmailErrorMessage({
  status
}: ErrorMessageProps) {
  const {
    locale
  } = useSite();
  const {
    urls: {
      loginAndReturnUrl
    }
  } = useUser();
  function getErrorMessageElementByStatus(): JSX.Element {
    const logInLink = <Link className="underline hover:no-underline" href={loginAndReturnUrl}>
        {{
        da: "Log ind",
        de: "Anmelden",
        en: "Log in",
        no: "Logg inn",
        sv: "Logga in"
      }[locale]}
      </Link>;
    switch (status) {
      case "USER_ALREADY_EXISTS":
        return <>
            <Translation model={userAlreadyExistsTranslation} /> {logInLink}
          </>;
      default:
        return <>
            {{
            da: "Der skete en fejl. Prøv venligst igen senere.",
            de: "Ein Fehler ist aufgetreten. Probieren Sie Später nochmal.",
            en: "An error has occurred. Please try again later.",
            no: "Det skjedde en feil. Vennligst prøv igjen senere.",
            sv: "Ett fel har inträffat. Försök igen senare."
          }[locale]}
          </>;
    }
  }
  return <span className="absolute select-none text-xs text-error-500" data-sentry-component="EmailErrorMessage" data-sentry-source-file="EmailErrorMessage.component.tsx">
      {getErrorMessageElementByStatus()}
    </span>;
}

/* eslint-enable */