"use client";

import { useEffect, useState, type ChangeEvent } from "react";
import { CheckboxWithLabel } from "@/components/Checkbox/CheckboxWithLabel.component";
import { Link } from "@/components/Link/Link.component";
import { fetchTerms } from "@/components/Terms/_api/fetch-terms";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { captureClientException } from "@/services/logging/capture-client-exception";
type SubscriptionTermsCheckboxProps = {
  readonly className?: string;
  readonly id?: string;
  readonly isDisabled?: boolean;
  readonly isRequired?: boolean;
  readonly type: "business" | "consumer";
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export function SubscriptionTermsCheckbox({
  className,
  id,
  isDisabled,
  isRequired,
  type,
  onChange
}: SubscriptionTermsCheckboxProps) {
  const {
    name
  } = useSite();
  const [url, setUrl] = useState<string>("#");
  useEffect(() => {
    const fetchTermLinks = async () => {
      const result = await fetchTerms();
      if (result === null) return false;
      if (type === "business") setUrl(result.businessSubscription);
      if (type === "consumer") setUrl(result.consumerSubscription);
    };
    fetchTermLinks().catch(captureClientException);
  }, [type]);
  return <CheckboxWithLabel className={className} id={id} isDisabled={isDisabled === true || url === "#"} isRequired={isRequired} onChange={onChange} data-sentry-element="CheckboxWithLabel" data-sentry-component="SubscriptionTermsCheckbox" data-sentry-source-file="SubscriptionTermsCheckbox.component.tsx">
      <Translation da="Jeg accepterer" de="Ich akzeptiere" en="I accept" no="Jeg godtar" sv="Jag accepterar" data-sentry-element="Translation" data-sentry-source-file="SubscriptionTermsCheckbox.component.tsx" />{" "}
      <Link className="text-primary hover:underline" disabled={url === "#"} href={url} rel="noreferrer" openInNewTab data-sentry-element="Link" data-sentry-source-file="SubscriptionTermsCheckbox.component.tsx">
        <Translation da={`${name}'s abonnementsvilkår`} de={`Nutzungsbedingungen von ${name}`} en={`subscription terms of ${name}`} no={`abonnementsvilkårene for ${name}`} sv={`abonnemangsvillkoren för ${name}`} data-sentry-element="Translation" data-sentry-source-file="SubscriptionTermsCheckbox.component.tsx" />
      </Link>
    </CheckboxWithLabel>;
}