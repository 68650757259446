"use client";

import type { ComponentType, FormEvent } from "react";
import { useEffect, useRef, useState } from "react";
import type { SubmitButtonProps } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { EmailErrorMessage } from "@/components/Input/Email/components/EmailErrorMessage.component";
import { Email } from "@/components/Input/Email/Email.component";
import { Name } from "@/components/Input/Name/Name.component";
import { Password } from "@/components/Input/Password/Password.component";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useFormValidation } from "@/hooks/use-form-validation.hook";
import type { Fetchable, ErrorMessageStatus } from "@/services/utilities/fetchable";
import { initial, isPending, pending, hasFailed } from "@/services/utilities/fetchable";
import { NewsletterTermsCheckbox } from "../Terms/NewsletterTermsCheckbox/NewsletterTermsCheckbox.component";
import { SubscriptionTermsCheckbox } from "../Terms/SubscriptionTermsCheckbox/SubscriptionTermsCheckbox.component";
import { UserTermsCheckbox } from "../Terms/UserTermsCheckbox/UserTermsCheckbox.component";
export type CreateUserFormData = {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly newsletterConsentGiven: boolean;
};
type CreateUserFormProps<FormStateType, EmailStateType> = {
  readonly initialFormState?: Fetchable<FormStateType>;
  readonly SubmitButton: ComponentType<SubmitButtonProps>;
  readonly onSubmit: (data: CreateUserFormData) => Promise<Fetchable<FormStateType>>;
  readonly onEmailValidation: (email: string) => Promise<Fetchable<EmailStateType>>;
  readonly termsType: "subscription-terms" | "user-terms";
};
export function CreateUserForm<FormStateType, EmailStateType>({
  initialFormState = initial(),
  onSubmit,
  SubmitButton,
  onEmailValidation,
  termsType
}: CreateUserFormProps<FormStateType, EmailStateType>) {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    isFormValid,
    checkFormValidity
  } = useFormValidation(formRef);
  const {
    trackClick
  } = useKilkayaClickEvent();
  const emailRef = useRef<HTMLInputElement>(null);
  const [formState, setFormState] = useState<Fetchable<FormStateType>>(initialFormState);
  const [emailState, setEmailState] = useState<Fetchable<EmailStateType>>(initial());
  useEffect(() => {
    emailRef.current?.setCustomValidity(hasFailed(emailState) ? emailState.errorMessage : "" // Setting customValidity to empty string means valid :)
    );
    checkFormValidity();
  }, [emailState, checkFormValidity]);
  async function validateEmail(email: string) {
    const isValid = Boolean(emailRef.current?.checkValidity());

    // Clear emailState and only call onEmailValidation if it is a valid, non-empty email
    if (!isValid || !email) {
      setEmailState(initial());
      return;
    }
    setEmailState(pending());
    setEmailState(await onEmailValidation(email));
  }
  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.
    if (!isFormValid) {
      return;
    }

    // FormData doesn’t include disabled fieldsets, so we have to get the data BEFORE setting pending state,
    // because this disables the input fields: https://www.silvestar.codes/articles/form-data-doesn-t-work-well-with-disabled-fieldset-elements/
    const formData = new FormData(formRef.current ?? undefined);
    const payload = {
      email: (formData.get("email") as string),
      firstName: (formData.get("firstName") as string),
      lastName: (formData.get("lastName") as string),
      newsletterConsentGiven: formData.get("newsletter-terms") === "on",
      password: (formData.get("password") as string)
    };

    // We have to set pending state after getting form data. See why in the comment above.
    setFormState(pending());
    trackClick({
      SubmitButton: "Paywall_Profile"
    });
    setFormState(await onSubmit(payload));
  }
  return <>
      <form className="mb-2 flex flex-col gap-5" ref={formRef} onSubmit={handleSubmit}>
        <Name data-testid="first-name" disabled={isPending(formState)} name="firstName" placeholder={{
        da: "Fornavn",
        de: "Vorname",
        en: "First name",
        no: "Fornavn",
        sv: "Förnamn"
      }} required onChange={checkFormValidity} data-sentry-element="Name" data-sentry-source-file="CreateUserForm.component.tsx" />
        <Name data-testid="last-name" disabled={isPending(formState)} name="lastName" placeholder={{
        da: "Efternavn",
        de: "Nachname",
        en: "Last name",
        no: "Etternavn",
        sv: "Efternamn"
      }} required onChange={checkFormValidity} data-sentry-element="Name" data-sentry-source-file="CreateUserForm.component.tsx" />
        <div>
          <Email disabled={isPending(formState)} name="email" ref={emailRef} required onChange={async event => validateEmail(event.target.value)} onClear={async () => validateEmail("")} data-sentry-element="Email" data-sentry-source-file="CreateUserForm.component.tsx" />

          {hasFailed(emailState) ? <EmailErrorMessage status={(emailState.errorMessage as ErrorMessageStatus)} /> : null}
        </div>
        <Password disabled={isPending(formState)} name="password" required onChange={checkFormValidity} data-sentry-element="Password" data-sentry-source-file="CreateUserForm.component.tsx" />
        <div className="grid gap-2">
          {termsType === "subscription-terms" ? <SubscriptionTermsCheckbox className="peer text-sm" id="create-user-terms" isDisabled={isPending(formState)} type="consumer" isRequired onChange={checkFormValidity} /> : null}
          {termsType === "user-terms" ? <UserTermsCheckbox className="peer text-sm" id="create-user-terms" isDisabled={isPending(formState)} isRequired onChange={checkFormValidity} /> : null}

          <NewsletterTermsCheckbox className="items-center" isDisabled={isPending(formState)} isRequired={false} onChange={checkFormValidity} data-sentry-element="NewsletterTermsCheckbox" data-sentry-source-file="CreateUserForm.component.tsx" />
        </div>
        <SubmitButton disabled={!isFormValid || isPending(formState)} isPending={isPending(formState)} data-sentry-element="SubmitButton" data-sentry-source-file="CreateUserForm.component.tsx" />
      </form>

      {hasFailed(formState) ? <ErrorMessage status={(formState.errorMessage as ErrorMessageStatus)} /> : null}
    </>;
}